/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/jsx-pascal-case */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {  useContext, useEffect, useMemo, useRef, useState } from "react";
import "../../../index.scss";
import "./event-einzeln.css";
import PostBox from "../../widgets/postbox.jsx";
import { Link, useParams, useNavigate, useSearchParams } from "react-router-dom";
import {
  collection,
  doc,
  getDoc,
  getDocs,
  onSnapshot,
  updateDoc,
  setDoc,
  query,
  where,
  deleteDoc,
  orderBy,
  limit,
} from "firebase/firestore";
import { firestore, storage } from "../../../firebase";
import FirebaseStorageImage from "../../functions/FirebaseStorageImage";
import { UserContext } from "../../../providers/UserProvider";
import { getDownloadURL, ref, uploadBytesResumable } from "firebase/storage";
import { Loader } from "../../../loader";
import "../home.css";
import { ModalTicketing } from "../ticketing/ticketing_anlegen";
import phoneFrame from "../../../graphics/phone_frame.png";
import LoaderDivWithOverlay, { hideLoader } from '../../widgets/loaderdiv';
import {
  Chart as ChartJS,
  LineElement,
  CategoryScale, //x axis
  LinearScale, //y axis
  PointElement,
  Tooltip,
  Title,
  Filler,
  ArcElement,
  BarElement,
} from "chart.js";
import OrganizerName from "../../widgets/OrganizerName";
import GenreName from "../../widgets/GenreName";
import VenueAddress from "../../widgets/VenueAddress";
import ScannerHinzufuegen from "../ticketing/scanner-hinzufuegen";
import { StatsContext } from "../../../providers/StatsProvider";
import Gaesteliste from "../ticketing/gaesteliste";
import ProgressBar from "@ramonak/react-progress-bar";
import { useCallback } from "react";
import { 
  viewsIcon,
  participantsIcon,
  chevronLeftIcon,
  chevronRightIcon,
  bookmarksIcon,
  sharesIcon,
  warningIcon,
  plusIcon,
  pdfIcon,
  chevronDownIcon,
  editIcon,
  secretLocationIcon,
  promoteIcon,
} from "../../../icons.jsx";
import { PictureModal, PostModal } from "../marketing.jsx";
import PieChart from "../../widgets/statistics_widgets/pieChart";
import FurtherInfo from "../../widgets/tooltip";
import BarChart from "../../widgets/statistics_widgets/barChart";
import LineGraph from "../../widgets/statistics_widgets/lineGraph";
import LineChart from "../../widgets/statistics_widgets/lineChart";
import ContextMenu__Event from "../../widgets/contextmenu_events.jsx";
import { linkCopiedToast } from "../../widgets/toasts.tsx";
import { recurrenceTextMap } from "../../global_config/recurrenceTextMap.jsx";
import ArtistBearbeiten from "../../widgets/ArtistEdit.jsx";
import ArtistDetails from "../../widgets/ArtistDetails.jsx";
import InstantPayout from "../ticketing/instant-payout.jsx";
import { OrganizerContext } from "../../../providers/OrganizerProvider.jsx";


function EventEinzeln() {
  
  // useContexts
  const { organizerId, permissions } = useContext(UserContext);
  const {getCustomerStatuses,getCustomerAges,
    getCustomerGenders,getCustomerGenres,
    getCustomerClubs, getCustomerOrigin} = useContext(StatsContext);
  const { loaded: statsLoaded, getTicketSales } = useContext(StatsContext);
  const { id } = useParams();
  const [searchParams] = useSearchParams();
  let navigate = useNavigate();


  
  const [eventData,setEventData] = useState(null)
  const [necessaryInp,setNecessaryInp] = useState(true)
  const [menuState,setMenuState] = useState(0)
  const [showModalTicketing,setShowModalTicketing] = useState(false)
  const [showModalAnlegen,setShowModalAnlegen] = useState(false)
  // const [comingSoon] = useState(true)
  const [eventOver,setEventOver] = useState(false)
  const [eventPost,setEventPost] = useState([])
  const [startTime, setStartTime] = useState("");
  const [endTime, setEndTime] = useState("");
  const [status, setStatus] = useState("Loading...");
  const [start,setStart] = useState(null)
  const [end, setEnd] = useState("");
  const [startDateRaw, setStartDateRaw] = useState("");
  const [endDateRaw, setEndDateRaw] = useState(null);
  const eventHasPassed = useMemo(() => endDateRaw < new Date(), [endDateRaw]);
  const [genre,lineup] = useMemo(() => [eventData?.genre,eventData?.lineup], [eventData]);
  const [hasInvoice,setHasInvoice] = useState(false)
  const [reloadTicketCategories,setReloadTicketCategories] = useState(true)

  // Week or Month Posts
  const [otherPosts,setOtherPosts] = useState([])
  const [pdfRef,setPdfRef] = useState(null)


  // For Modal
  const [show,setShow] = useState(false)
  const handleClose = () => setShow(false);

  // Show Enlarged Post Modal
  const [modalPic,setModalPic] = useState(null);

  // For Modal
  const [showGaesteListe,setShowGaesteListe] = useState(false)
  const handleCloseGaesteListe = () => setShowGaesteListe(false);

  // For Modal Artist
  const [showArtistEdit,setShowArtistEdit] = useState(false)
  const handleCloseArtistEdit = () => setShowArtistEdit(false);

  const [showPostModal,setShowPostModal] = useState(false)
  const [edit,setEdit] = useState(false)
  const [dataEdit,setDataEdit] = useState([])
  const [artistIdToEdit, setArtistIdToEdit] = useState(null)

  const [ticketingLoading,setTicketingLoading] = useState(true)
                         
  const [navBarVisible, setNavBarVisible] = useState(false)
  const [isParentEvent,setIsParentEvent] = useState(false)
  const [isSubeventDraft,setIsSubeventDraft] = useState(false)
  const [isSubevent, setIsSubevent] = useState(false)
  const [isFirstEvent, setIsFirstEvent] = useState(false);
  const [nextRecurringEvents,setNextRecurringEvents] = useState(null);


  const [customerStatuses, customerStatusesLabels] = getCustomerStatuses({ event: id });
  const [customerAges, customerAgesLabels] = getCustomerAges({ event: id });
  const [customerGenders, customerGendersLabels] = getCustomerGenders({ event: id });
  const [customerOrigin, customerOriginLabels] = getCustomerOrigin({ event: id });
  const [customerClubs, customerClubsLabels] = getCustomerClubs({ event: id });
  const [customerGenres, customerGenresLabels] = getCustomerGenres({ event: id });
  const menuRef = useRef(null);

  useEffect(() => {
    // Fetch Week posts or Month posts including the specific event
    getDocs(
      query(
        collection(firestore,"social_media_posts"),
        where("organizer","==",organizerId),
        where("events","array-contains",id),
        orderBy("post_at","desc")
        )
        ).then((posts) => {
          setOtherPosts(posts.docs)
        })
        
        // Fetch Eventpost of the specific event
        getDocs(
          query(
            collection(firestore,"social_media_posts"),
            where("organizer","==",organizerId),
            where("event","==",id), 
            orderBy("post_at","desc"))
        ).then((post) => {
          setEventPost(post.docs)
        })
  },[])

  const animate = useCallback((index) => {
    document.getElementById("tab-wrapper").classList='';
    setTimeout(() => {
      setMenuState(index)
      if(index > menuState){
        document.getElementById("tab-wrapper").classList="animate__animated animate__fadeInRight"
      } else if(index < menuState) {
        document.getElementById("tab-wrapper").classList="animate__animated animate__fadeInLeft"
      }
    }, 100)
  }, [menuState])

  const collectionMap = {
    true: "recurring_events",
    false: "events",
  }

  useEffect(() => {
    let viewParent = searchParams.get("viewParent") === "true";
    document.getElementsByClassName("loaderDiv")[0].style.visibility =
      "visible";
    onSnapshot(doc(firestore, collectionMap[viewParent], id), (event) => {
        setIsParentEvent(viewParent)
        setEventData(event?.data())
      }
      ) 
  }, [id]);

  useEffect(() => {
    // Set the Status of the event 
    if(eventData?.canceled){
      setStatus("Abgesagt")
    }
    else if (eventData?.visibility === "draft") {
      setStatus('Offline');
    }
    else if (eventData?.pending_review) {
      setStatus('Wird überprüft');
    }
    else if (eventData?.visibility === "private") {
      setStatus("Privat")
    }
    else {
      setStatus('Online');
    }

    // Check wether all necessary Inputs are set
    setNecessaryInp( 
      eventData?.name
      && eventData?.start
      && eventData?.images?.length
      && (eventData?.address || eventData?.venue)
      && eventData?.genre?.length
      && (eventData?.price || eventData?.free || eventData?.box_ticket_price || ["standard", "hidden"].includes(eventData?.ticket))
      && eventData?.description
    )

    // Some Date Formatting
    if(eventData?.start !== ""){
      setStart(eventData?.start?.toDate()?.toLocaleDateString("de-DE"));
      setStartDateRaw(eventData?.start?.toDate())
      setEndDateRaw(eventData?.end?.toDate())
      setStartTime(eventData?.start?.toDate()?.toLocaleTimeString("de-DE")?.split(" ")[0].substring(0,5));
      setEnd(eventData?.end?.toDate()?.toLocaleDateString("de-DE"));
      if(eventData?.start?.toDate() < new Date()) setEventOver(true)
      if(eventData?.end !== "openEnd"){
        setEndTime(eventData?.end?.toDate()?.toLocaleTimeString("de-DE")?.split(" ")[0].substring(0,5));
      }
      else{
        setEnd("openEnd")
      }
    }

    if (["standard", "hidden","completed"].includes(eventData?.ticket)) {
      setTicketingLoading(false)
    }

    // Recurring Event Stuff
    setIsSubeventDraft(eventData?.recurring_event && eventData.visibility === "draft")
    setIsSubevent(![null,undefined].includes(eventData?.recurring_event) && eventData?.recurring_event.length > 1)
    
    // Set the PDF Ref when there is an invoice
    if(eventData?.has_invoice || eventData?.hasInvoice){
      getDocs(
        query(
          collection(firestore,"documents"),
          where("event","==",id),
          where("type","==","invoice")
        )).then( (invoice) => {
          setPdfRef(invoice.docs[0].data().pdf);
          setHasInvoice(true)
        }
      )
    }
    if(document.getElementsByClassName("loaderDiv")[0] != null) document.getElementsByClassName("loaderDiv")[0].style.visibility = "hidden";
    if(document.getElementById("status") != null) document.getElementById("status").style.visibility = "visible";
  },[eventData])

  useEffect(() => {
    if(!eventData?.recurring_event) return;
    getDocs(
      query(
        collection(firestore,"events"),
        where("start", ">", new Date()),
        where("recurring_event","==",eventData?.recurring_event),
      ),
    ).then((events) => {
      setNextRecurringEvents(events?.docs);
      
    });
  }, [eventData?.recurring_event]);

  ChartJS.register(
    LineElement,
    CategoryScale,
    LinearScale,
    PointElement,
    Tooltip,
    Title,
    ArcElement,
    BarElement,
    Filler)

    
    const handleLinkCopyClick = (e,id) => {
      e.stopPropagation();
      e.preventDefault();
      window.navigator.clipboard.writeText(`https://t.elgio.de/${id}`)
  }


  const handleClickOutside = (event) => {
    if (menuRef.current && !menuRef.current.contains(event.target)) {
      setNavBarVisible(false);
    }
  };

  useEffect(() => {
    if (navBarVisible) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }

    // Cleanup event listener on component unmount
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [navBarVisible]);


  const isFullWidth = window.innerWidth < 768;

  useEffect(() => {
    getDocs(
      query(
        collection(firestore, "events"),
        where("organizer", "==", organizerId),
        orderBy("start"),
        limit(1)
      )
    ).then((events) => {
      setIsFirstEvent(events.docs[0]?.id === id);
    });
  }, [id, organizerId]);

  return (
    <div className="content">
      <LoaderDivWithOverlay></LoaderDivWithOverlay>
      <div className="flex-gap-10" id="breadcrumb-title-text">
        <div style={{cursor: "pointer"}} onClick={() => navigate(-1)}>{chevronLeftIcon}</div>
        <div>
          <Link to="/dashboard/events" className="breadcrumb">Events</Link>
          &nbsp;/&nbsp;
          {eventData?.name}
        </div>
      </div>
      {eventData?.sponsored === true && <div className="not-all-inputs-done" id="promoInput">
        <div>{promoteIcon}</div>
        <div>Du hast dieses Event promoted</div>
      </div>}
      <Link to={"/dashboard/events/event-bearbeiten/" + id} style={necessaryInp ? {display: "none"} : {visibility:"visible"}}>
        <div className="not-all-inputs-done" id="warningInput">
          <div>{warningIcon}</div>
          <div id="notallInpText">Achtung: Diesem Event fehlen noch erforderliche Angaben. Klicke hier oder auf Bearbeiten um diese zu ergänzen.</div>
        </div>
      </Link>
      {eventData?.pending_review && (isFirstEvent ? <div className="not-all-inputs-done" id="disclaimerInput">
        <div>{warningIcon}</div>
        <div id="notallInpText">Dein erstes Event wird von uns manuell überprüft. In Zukunft geschieht diese Überprüfung i.d.R. automatisch und sofortig.</div>
      </div> : <div className="not-all-inputs-done" id="disclaimerInput">
        <div>{warningIcon}</div>
        <div id="notallInpText">Dieses Event konnte nicht automatisch überprüft werden und wird von uns manuell überprüft.</div>
      </div>)}
      <div className="eventEinzelnBox" style={{height:"100%",position:"relative"}}>
        {(isParentEvent || eventData?.recurring_event) && <div className="navbar-eventreihe" ref={menuRef}>
          <div className="navbar-eventreihe-header" onClick={() => setNavBarVisible(!navBarVisible)}>
            <div>{isParentEvent ? 'Eventreihe' : start}</div>
            <div>{navBarVisible ?  chevronDownIcon : chevronLeftIcon}</div>
          </div>

          {navBarVisible && 
            <div className="navbar-eventreihe-items">
              <Link to={"/dashboard/events/event/" + eventData?.recurring_event + "?viewParent=true"} className="navbar-eventreihe-item" onClick={() => setNavBarVisible(false)}>Eventreihe</Link>
              {nextRecurringEvents?.map((event) => {
                return(
                  <Link to={"/dashboard/events/event/" + event.id} className="navbar-eventreihe-item" onClick={() => setNavBarVisible(false)}>{event?.data()?.start?.toDate().toLocaleDateString("de-DE")}</Link>
                )
              })}
            </div>
          }
        </div>}
        <div className="status-div" id="status" style={
          status === 'Online'
            ? !necessaryInp
              ? { visibility: "hidden", backgroundColor: "var(--bgGreen)"}
              : {visibility:"hidden",backgroundColor: "var(--bgGreen)"}
            : status === 'Wird überprüft'
              ? { visibility: "hidden", backgroundColor: "var(--bgOrange)"}
              : necessaryInp
                ? {visibility: "hidden",backgroundColor: "var(--bgRed)"} 
                : { visibility: "hidden", backgroundColor: "var(--bgRed)"}
        }>
          <div style={{color: "var(--buttonCoMain)"}}>
            Status: &nbsp;
            <span style={{color: "var(--buttonCoMain)", fontWeight: "600"}}>{status}</span>  
          </div>
        </div>
        <FirebaseStorageImage reference={eventData?.images?.length > 0 ? eventData?.images[0] : null}
          style={{
            width: "100%",
            height: "18vh",
            objectFit: "cover",
            borderRadius: "7px 7px 0px 0px ",
          }}
        ></FirebaseStorageImage>
        <div className="below-img-text">
          <div className="below-img-left">
            <div style={{ height: "100%" }}>
              <div id="BoxHeaderTitle">{eventData?.name}</div>
              <div className="BoxHeaderTitleDetails-Flex">
              {
                isParentEvent ?
                  <div id="BoxHeaderTitleDetails">  
                    {startTime !== undefined && eventData?.recurrence !== "-" ? recurrenceTextMap[eventData?.recurrence] + " ab " +  startTime +  " Uhr " : eventData?.recurrence !== "-" ? recurrenceTextMap[eventData?.recurrence] : "-"}
                  </div>
                : 
                <div id="BoxHeaderTitleDetails">
                   {start !== undefined && startTime !== undefined ? start + " ab " +  startTime +  " Uhr " : "Kein Datum"}
                  {startTime !== endTime && end !== "openEnd" && end !== null && endTime !== null && <>bis {endTime} Uhr</>}
                </div>
              }

                <div className="event-venue">
                  <div style={{fontSize: "var(--fontsize-medium", fontWeight: "600", color: "var(--textMain)", whiteSpace: "nowrap"}}>
                    <OrganizerName id={eventData?.venue} alt={eventData?.address ?? ""}/> 
                  </div>
                    - 
                  <div style={{fontSize: "var(--fontsize-medium"}}>
                    <VenueAddress id={eventData?.venue} alt={eventData?.address} />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="below-img-right">
            <div className="ButtonsBoxEinzel">
              <div className="row align_center" id="buttonFlexEvent">

              {!["public"].includes(eventData?.visibility) && (
                  <a
                    id="ButtonsBoxItem"
                    className="bg-green Button white"
                    onClick={async () => {
                      if(startDateRaw < new Date()){
                        alert("Das Startdatum liegt in der Vergangeheit! Bitte änder dies um das Event live schalten zu können.")
                      }
                      else{
                      if (
                        necessaryInp &&
                        window.confirm(
                          "Du bist dabei das Event Live zu schalten.\nDanach kann dies nur noch von uns umgestellt werden!\nBist du dir sicher?"
                        ) === true 
                      ) {
                        updateDoc(doc(firestore, "events", id), {
                          visibility: "private",
                          pending_review: true,
                        });
                        //Marketing stuff
                        const data = (await getDoc(doc(firestore, "events", id))).data();
                        if(data.sync_to_external?.filter(e => e !== 'facebook_event').length > 0) {
                          setDoc(doc(collection(firestore,"social_media_posts")), {
                            type: 'event',
                            event: id,
                            post_at: new Date(),
                            caption: data.description,
                            banner: data.images[0],
                            organizer: organizerId,
                            facebook_post: data.sync_to_external.includes('facebook_post'),
                            facebook_story: data.sync_to_external.includes('facebook_story'),
                            instagram_post: data.sync_to_external.includes('instagram_post'),
                            instagram_story: data.sync_to_external.includes('instagram_story'),
                          });
                        }
                        if(data.sync_to_external?.includes('facebook_event')) {
                          const newwindow = window.open(`https://www.facebook.com/events/create?elgio_event_id=${id}`, 'fb_event');
                          if (window.focus) { newwindow.focus() }
                        }
                      }
                    }}
                    }
                    style={necessaryInp
                      ? {
                        border: "none",
                        }
                      : {
                        border: "none",
                        backgroundColor:"rgb(21, 119, 66)",
                        color:"rgb(71, 71, 71)",
                        cursor:"default",
                    }}
                  >
                    <div id="editBtnText">Live Schalten</div>
                  </a>
                )}
                {eventData?.visibility === "public" && 
                  <div className="bg-grey Button white" onClick={(e) => {handleLinkCopyClick(e,id);linkCopiedToast()}} style={ isFullWidth ? {width: "fit-content"} : {}}>Eventlink kopieren</div>
                  &&
                  <Link to={`/event-form/success?event=${id}&redirected_from_dashboard=true`}>
                    <div className="bg-green Button white" style={ isFullWidth ? {width: "fit-content"} : {}}>Event promoten</div>
                  </Link>
                }

                {permissions?.events?.edit && <Link
                  id="ButtonsBoxItem"
                  className="bg-blue Button"
                  to={isParentEvent ? "/dashboard/events/event-bearbeiten/" + id + "?viewParent=true"  : "/dashboard/events/event-bearbeiten/" + id}
                  onClick={(e) => {if(startDateRaw < new Date() && eventData?.visibility === "public"){window.alert("Du kannst dieses Event nicht mehr bearbeiten,\nweil es in der Vergangeheit liegt!"); e.stopPropagation();e.preventDefault()}}}
                >
                  {editIcon}
                  <div className="buttonText">Bearbeiten</div>
                </Link>}
                {isSubevent && !isParentEvent && <Link
                  id="ButtonsBoxItem"
                  className="bg-blue Button"
                  to={isParentEvent ? "/dashboard/events/event-bearbeiten/" + id + "?viewParent=true" : isSubeventDraft ? "/dashboard/events/event-bearbeiten/" + eventData?.recurring_event  + "?viewParent=true&showToast=true" : "/dashboard/events/event-bearbeiten/" + id}
                  onClick={(e) => {if(startDateRaw < new Date() && eventData?.visibility === "public"){window.alert("Du kannst dieses Event nicht mehr bearbeiten,\nweil es in der Vergangeheit liegt!"); e.stopPropagation();e.preventDefault()}}}
                >
                  <div id="editBtnText">Eventreihe Bearbeiten</div>
                </Link>}
                <div className="col-flex flex-gap-1rem justify-center align-center">
                  <div className="dropdown-button-einzel">
                    <ContextMenu__Event id={id} visibility={eventData?.visibility} startDateRaw={startDateRaw} canceled={eventData?.canceled} pendingReview={eventData?.pending_review}></ContextMenu__Event>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="stats-box-event">
        <div id="aufrufe" className="Stats-Event-Einzeln">
          <div className="StatsHeader">
            <div className="StatsIconEventEinzeln" style={{backgroundColor: "var(--bgBlue)"}}>{viewsIcon}</div>
            <div id="StatsTitelEventEinzeln">Aufrufe</div>
          </div>
          <div className="statsAnzahlBody">
            <div className="stats-counter animate__animated animate__fadeInDown">{eventData?.view_count ?? 0}</div>
          </div>
        </div>
        <div id="teilungen" className="Stats-Event-Einzeln">
          <div className="StatsHeader">
            <div className="StatsIconEventEinzeln" style={{backgroundColor: "var(--bgGreen)"}}>{participantsIcon}</div>
            <div id="StatsTitelEventEinzeln">Teilnehmer</div>
          </div>
          <div className="statsAnzahlBody">
            <div className="stats-counter animate__animated animate__fadeInDown">{eventData?.attendee_count ?? 0}</div>
          </div>
        </div>
        <div id="follower" className="Stats-Event-Einzeln">
          <div className="StatsHeader">
            <div className="StatsIconEventEinzeln" style={{backgroundColor: "var(--bgOrange)"}}>{bookmarksIcon}</div>
            <div id="StatsTitelEventEinzeln">Gespeichert</div>
          </div>
          <div className="statsAnzahlBody">
            <div className="stats-counter animate__animated animate__fadeInDown">{eventData?.save_count ?? 0}</div>
          </div>
        </div>
        <div id="ticketkaeufer" className="Stats-Event-Einzeln">
          <div className="StatsHeader">
            <div className="StatsIconEventEinzeln" style={{backgroundColor: "var(--purple)"}}>{sharesIcon}</div>
            <div id="StatsTitelEventEinzeln">Teilungen</div>
          </div>
          <div className="statsAnzahlBody">
            <div className="stats-counter animate__animated animate__fadeInDown">{eventData?.share_count ?? 0}</div>
          </div>
        </div>
      </div>
      <div className="col-flex">
        <div className="row-flex" style={{justifyContent:"space-around",gap:".3rem"}}>
          <div className={menuState === 0 ? "eventMenuTile eventMenuTileActive" : "eventMenuTile"} onClick={() => {animate(0)}} id="detailsTabEvent">Details</div>
          {permissions?.ticketing?.view && <div className={menuState === 1 ? "eventMenuTile eventMenuTileActive" : "eventMenuTile"} onClick={() => {animate(1)}} id="ticketingTabEvent">Ticketing</div>}
          {permissions?.statistics?.view && <div className={menuState === 2 ? "eventMenuTile eventMenuTileActive" : "eventMenuTile"} onClick={() => {animate(2)}} id="statistikenTabEvent">Statistiken</div>}
          {/* {permissions?.events?.view && <div className={menuState === 3 ? "eventMenuTile eventMenuTileActive" : "eventMenuTile"} onClick={() => {animate(3)}} id="galerieTabEvent">Galerie</div>} */}
        </div> 
        <div className="tabContent"> 
          <div id="tab-wrapper">
            {(() => {
              switch(menuState){
                case 0: return <DetailsTab eventData={eventData} setShowArtistEdit={setShowArtistEdit} setArtistIdToEdit={setArtistIdToEdit} start={start} startTime={startTime} endTime={endTime} end={end} genre={genre} lineup={lineup} organizerId={organizerId} permissions={permissions} id={id}></DetailsTab>
                // case 1: return <MarketingTab isParentEvent={isParentEvent} eventPost={eventPost} setDataEdit={setDataEdit} setShowPostModal={setShowPostModal} modalPic={modalPic} setModalPic={setModalPic} otherPosts={otherPosts}></MarketingTab>
                case 1: return <TicketingTab  setShowModal={setShowModalTicketing} setShowModalAnlegen={setShowModalAnlegen} getTicketSales={getTicketSales} id={id} statsLoaded={statsLoaded} pdfRef={pdfRef} isParentEvent={isParentEvent} eventData={eventData} eventOver={eventOver} hasInvoice={hasInvoice} setShow={setShow} setShowGaesteListe={setShowGaesteListe} ticketingLoading={ticketingLoading} reloadTicketCategories={reloadTicketCategories} setReloadTicketCategories={setReloadTicketCategories}></TicketingTab>
                case 2: return <StatistikenTab customerStatuses={customerStatuses} customerStatusesLabels={customerStatusesLabels} customerGenders={customerGenders} customerGendersLabels={customerGendersLabels} customerAges={customerAges} customerAgesLabels={customerAgesLabels} customerGenres={customerGenders} customerGenresLabels={customerGendersLabels} customerOrigin={customerOrigin} customerOriginLabels={customerOriginLabels} customerClubs={customerClubs} customerClubsLabels={customerClubsLabels} ></StatistikenTab>
                // case 3: return <GalerieTab id={id} isParentEvent={isParentEvent} eventHasPassed={eventHasPassed}></GalerieTab>
                default: return <DetailsTab></DetailsTab>
              }
            })()}    
          </div>
        </div>
      </div>
      <Loader></Loader>
      <ScannerHinzufuegen show={show} handleClose={handleClose}></ScannerHinzufuegen>
      <Gaesteliste show={showGaesteListe} handleClose={handleCloseGaesteListe}></Gaesteliste>
      <ArtistBearbeiten show={showArtistEdit} handleClose={handleCloseArtistEdit} artistId={artistIdToEdit} lineup={lineup} indexToChange={lineup && eventData.lineup.indexOf(artistIdToEdit)}></ArtistBearbeiten>
      <ModalTicketing 
        startDate={start}
        startTime={startTime} 
        venue={eventData?.venue}
        location={eventData?.address}
        eventName={eventData?.name ?? ""}
        showModal={showModalTicketing}
        setShowModal={setShowModalTicketing}
        setReloadTicketCategories={setReloadTicketCategories}
        eventID={id}
        edit
      ></ModalTicketing>
      <ModalTicketing 
        startDate={start}
        startTime={startTime} 
        venue={eventData?.venue}
        location={eventData?.address}
        eventName={eventData?.name}
        showModal={showModalAnlegen}
        setShowModal={setShowModalAnlegen}
        eventID={id}
      ></ModalTicketing>
      <PostModal idEdit={id} setEdit={setEdit} show={showPostModal} setShow={setShowPostModal} edit={edit} dataEdit={dataEdit}></PostModal>
    </div>
  );




}
// DETAILS TAB

function DetailsTab({eventData,start,startTime,end,endTime,genre,lineup,permissions,organizerId,id,setShowArtistEdit=() => {},setArtistIdToEdit=() => {},}){
  return(
      <div className="detailsTab" id="details">
        <div className="detailsTabColumn">
          <div className="detailsbox">
            <div className="detailsheader">Eventname</div>
            <div className="detailssubheader" style={eventData?.name && eventData?.name.length === 0 ? {fontStyle:"italic"}  : {fontStyle:"unset"}}>{eventData?.name ?? ""}</div>
          </div>
          <div className="detailsbox">
            <div className="detailsheader">Datum & Uhrzeit</div>
            <div className="detailssubheader" style={ (start && startTime ) === undefined ? {fontStyle:"italic"}  : {fontStyle:"unset"}}>
            {start !== undefined && startTime !== undefined ? "Ab: " + start +  " " + startTime : 'Kein Datum'}</div>
            <div className="detailssubheader" style={ (endTime && end) === undefined ? {fontStyle:"italic"}  : {fontStyle:"unset"}}>{endTime && end ? "Bis: " +  end + " " + endTime : ""}</div>
          </div>
          <div className="detailsbox">
            <div className="detailsheader">Adresse</div>
            <div className="detailssubheader" style={eventData?.address && eventData?.address?.length === 0 ? {fontStyle:"italic"} : {fontStyle:"unset"}}>
              {eventData?.secret_location ?
              <span className="col-flex">
                <span>{secretLocationIcon} Secret Location (wird bekannt gegeben {eventData?.secret_location_reveal_time} vor Beginn)</span>
                <span>{eventData?.address}</span>
              </span>
              : <span className="col-flex">
                <span>{eventData?.venue_data?.name}</span>
                <span>{eventData?.address}</span>
              </span>}
            </div>  
          </div>
          <div className="detailsbox">
            <div className="detailsheader">Musikgenre</div>
            <div className="detailssubheader" style={ genre && genre?.length === 0 ? {fontStyle:"italic"} : {fontStyle:"unset"}}>
              <div className="col-flex">
                {genre?.length > 0 ? genre.map((e, i) => <div key={e + "a"}><GenreName key={e} id={e} />{i !== genre.length - 1 && ''}</div>) : 'Keine Musikrichtugen'} 
              </div>
            </div>  
          </div>
          <div className="detailsbox">
            <div className="detailsheader">Mindestalter</div>
            <div className="detailssubheader" style={eventData?.min_age?.toString() === undefined ? {fontStyle:"italic"}  : eventData?.min_age?.toString() === "0" ? {fontSize:"var(--fontsize-massive)"} :{fontStyle:"unset"}}>
              {eventData?.min_age?.toString() === "0" ? "Kein Mindesalter" : eventData?.min_age?.toString() ?? 'Kein Mindestalter'}
            </div>
          </div>
          <div className="detailsbox">
            <div className="detailsheader">Dresscode</div>
            <div className="detailssubheader" style={ eventData?.dresscode === null ? {fontStyle:"italic"}  : {fontStyle:"unset"}}>{eventData?.dresscode??'Kein Dresscode'}</div>
          </div>
          <div className="detailsbox">
            <div className="detailsheader">Beschreibung</div>
            <div className="detailssubheader" style={eventData?.description && eventData?.description?.length === 0  ? {fontStyle:"italic"} :{maxHeight:"100px",overflowY:"auto"}}>{eventData?.description && eventData?.description.length > 0 ? eventData?.description : "Keine Beschreibung"}</div>
          </div>
          <div className="detailsbox">
            <div className="detailsheader">Lineup</div>
            <div className="detailssubheader pointer gap-2 col-flex" style={ lineup && lineup?.length === 0 ? {fontStyle:"italic"}  : {maxHeight:"300px",overflowY:"auto"}}>
              {lineup?.length > 0 ? 
                lineup.map(e => e.toString().startsWith("$")
                  ? <div key={e}>{e}</div>
                  : <div key={e + "b"}  onClick={permissions?.events?.edit ? () => {setShowArtistEdit(true);setArtistIdToEdit(e)} : () => {}} className="row-flex align_center justify_space_between flex-gap-1rem">
                      <ArtistDetails id={e}/> {permissions?.events?.edit && editIcon}
                    </div>
                )
              : 'Kein Lineup'}
            </div>
          </div>
          <div className="detailsbox">
            <div className="detailsheader">Co-Veranstalter</div>
            <div className="detailssubheader" style={eventData?.organizer && eventData?.organizer.filter(e => e !== eventData?.venue && e !== organizerId).length === 0 ? {fontStyle:"italic"} : {fontStyle:"unset"}}> 
            { (() => {
                if(!eventData?.organizer) return <>Keine Co-Veranstalter</>;
                const coorgnizers = eventData?.organizer.filter(e => e !== eventData?.venue && e !== organizerId);
                if(coorgnizers.length === 0) return <>Keine Co-Veranstalter</>;
                return coorgnizers.map(e => <div key={e} ><OrganizerName key={e} id={e} /></div>);
              })() } 
            </div>
          </div>
          <div className="detailsbox">
           <div className="detailsheader">Eintritt</div>
           <div className="detailssubheader priceForNaN" style={eventData?.price === null ? {fontStyle:"italic"} : {fontStyle:"unset"}}>
            {eventData?.price === 0 
              ? <span> Freier Eintritt </span> 
              : eventData?.price 
                ? <>
                    <span>ab&nbsp;</span>
                    <span>{eventData?.price.toFixed(2).replace(".", ",")}€</span>
                  </> 
                : <span> Kein Eintritt </span>
            }
          </div>
          </div>
          <div className="detailsbox">
            <div className="detailsheader">Specials</div>
            <div className='detailssubheader col-flex' style={eventData?.specials && eventData?.specials?.length === 0 ? {fontStyle:"italic"}  : {fontStyle:"unset"}}>{
            eventData?.specials?.length > 0 ?
            eventData?.specials.map((s) => {
              return(<span key={s}>{s}</span>)
            })
            : "Keine Specials"
            }</div>
          </div>
        </div>
        <div className="previewAppEvent">
          <div className='websiteVorschauBox' style={{marginBottom: "20px"}}>
            <div className="profilheaders">Vorschau in der App</div>
            <FurtherInfo 
              explanation="Hier siehst du eine mobile Vorschau deines Events. Manchmal dauert es bis zu 2min bis Änderungen hier sichtbar sind."
            />
          </div>
          <div className="previewContainer">
              <img src={phoneFrame} alt="phone_frame_for_preview" className='phoneFramePreview'/>
              <div className="iframeContainer" style={{width: "89%"}}>
                <LoaderDivWithOverlay id="preview"/>
                <iframe title="webapp-preview-event-einzeln" onLoad={() => hideLoader("preview")} src={`https://elgio.de/event/${id}?hide_navigation=true`} style={{ transformOrigin: '0% 0%', border: 'none', margin: 0, padding: 0, height: '100%', width: '100%' }} />
              </div>
          </div>
        </div>
      </div>
    );
  }



// MARKETING TAB

function MarketingTab({isParentEvent,eventPost,setDataEdit=() => {},setShowPostModal=() => {},setModalPic=() => {},otherPosts,modalPic}){
  const { hasPro } = useContext(OrganizerContext);

  const deletePost = async (id) => {
    if(window.confirm("Sind du dir sicher, dass du diesen Post löschen willst?")){
        await deleteDoc(doc(collection(firestore,"social_media_posts"),id))

    }
  }
  
  return(
    <>
      { 
      isParentEvent ? 
      <div id="noTicketing">
      <div>Das funktioniert noch nicht mit Eventreihen!</div>
      </div>
      :
      hasPro ? 
      <div className="MarketingBox">
        <div className="eventPostBox col-flex flex-gap-1rem">
          <div className="TextWhite" style={{fontSize:"var(--fontsize-huge)"}}>Du hast folgende Posts zu diesem Event:</div>
          {eventPost.length !== 0 ? 
          eventPost.map((post) => {
            return(
              <PostBox
                post_at_raw={post.data()?.post_at?.toDate()}
                post_at={post.data()?.post_at?.toDate().toLocaleDateString("de-DE",{weekday: 'short', day: '2-digit', month: '2-digit', year: 'numeric'}) + " um " + post.data().post_at.toDate().toLocaleTimeString("de-DE",{hour:"2-digit",minute:"2-digit"})}    
                banner={post.data()?.banner}
                status={post.data()?.status}
                wholeDoc={post.data()}
                setDataEdit={setDataEdit}
                deletePost={deletePost}
                setShow={setShowPostModal}
                setModalPic={setModalPic}
                id={post.id}
                repeat={post.data()?.repeat}
                instagram_story={post.data().instagram_story}
                instagram_post={post.data().instagram_post}
                facebook_story={post.data().facebook_story}
                facebook_post={post.data().facebook_post}
                type={post.data().type}
                caption={post.data().caption}
              ></PostBox>
            )
          }) : <div className="noEvents">Es gibt keine Eventposts zu diesem Event</div>}
          <PictureModal pic={modalPic} setPic={setModalPic} ></PictureModal>
        </div>
        <div className="otherPostBox col-flex flex-gap-1rem">
          <div className="TextWhite" style={{fontSize:"var(--fontsize-huge)"}}>Dieses Event ist in folgenden anderen Posts gefeatured:</div>
          {otherPosts.length !== 0 ? otherPosts.map((post) => {
            return(
              <PostBox
                post_at_raw={post.data()?.post_at?.toDate()}
                post_at={post.data()?.post_at?.toDate().toLocaleDateString("de-DE",{weekday: 'short', day: '2-digit', month: '2-digit', year: 'numeric'}) + " um " + post.data().post_at.toDate().toLocaleTimeString("de-DE",{hour:"2-digit",minute:"2-digit"})}    
                banner={post.data()?.banner}
                status={post.data()?.status}
                wholeDoc={post.data()}
                setDataEdit={setDataEdit}
                deletePost={deletePost}
                setShow={setShowPostModal}
                setModalPic={setModalPic}
                id={post.id}
                repeat={post.data()?.repeat}
                instagram_story={post.data().instagram_story}
                instagram_post={post.data().instagram_post}
                facebook_story={post.data().facebook_story}
                facebook_post={post.data().facebook_post}
                type={post.data().type}
                caption={post.data().caption}
              ></PostBox>
            )
          }) : <div className="noEvents">Dieses Event ist in keinen anderen Posts gefeatured</div> }
          <PictureModal pic={modalPic} setPic={setModalPic} ></PictureModal>
        </div>
      </div>
      :
      <div id="noTicketing">
        <div>Du hast diese Funktion noch nicht freigeschaltet!</div>
        <Link to={"/dashboard/marketing"}>
          <div className="newPassw flex-gap-1rem white">
            <div>{plusIcon}</div>
            <div>Kostenlos testen</div>
          </div>
        </Link>
      </div>
      }
    </>
  )
}



// TICKETING TAB

function TicketingTab({getTicketSales,id,statsLoaded,reloadTicketCategories,setReloadTicketCategories,pdfRef,isParentEvent,eventData,eventOver,hasInvoice,setShowGaesteListe,setShow,ticketingLoading,...props}){
  const {permissions, organizerId} = useContext(UserContext);
  const [ticketKategorien,setTicketKategorien] = useState([])
  const [totalTicketsEvent, setTotalTicketsEvent] = useState(0);
  // eslint-disable-next-line no-unused-vars
  const [statusTicketing, setStatusTicketing] = useState('');
  const [ticketSalesWeekSums,, ticketSalesWeekNum, ticketSalesWeekLabels] = getTicketSales({timeframe: 'week', event: id});
  const [ticketSalesTodaySums,, ticketSalesTodayNum, ticketSalesTodayLabels] = getTicketSales({timeframe: 'today', event: id});
  const [ticketSalesYesterdaySums,, ticketSalesYesterdayNum, ticketSalesYesterdayLabels] = getTicketSales({timeframe: 'yesterday', event: id});
  const [ticketSalesLast30DaysSums,, ticketSalesLast30DaysNum, ticketSalesLast30DaysLabels] = getTicketSales({timeframe: 'last_30_days', event: id});
  const [ticketSalesState,setTicketSalesState] = useState(2)                   
  const [ticketSalesActiveSums,setTicketSalesActiveSums] = useState([])
  const [ticketSalesActiveNum,setTicketSalesActiveNum] = useState(0)
  const [ticketSalesActiveLabels,setTicketSalesActiveLabels] = useState([])                   
  const [ticketSalesActiveText,setTicketSalesActiveText] = useState("Heute")
  const [completedTotal, setCompletedTotal] = useState(0);
  const [availableTicketsEvent, setAvailableTicketsEvent] = useState(0);

  useEffect(() => {
    return(() => {
      setReloadTicketCategories(true)
    })
  },[])


    //payout
    const [payoutAmount, setPayoutAmount] = useState(null);
    const [payoutTime, setPayoutTime] = useState(null);
    const [paidOut, setPaidOut] = useState(false);
    useEffect(() => {
      Promise.all([
        getDocs(query(
          collection(firestore, 'tickets'),
          where('event', '==', id),
          where('status', 'in', ['confirmed', 'redeemed', 'unredeemed']),
        )),
        getDocs(query(
          collection(firestore, 'events', id, 'tickets'),
          where('seller', '==', organizerId),
        )),
      ]).then(([ticketTypesSnap, ticketsSnap]) => {
        let amount = 0;
        let time = new Date(1970);
        for(const ticket of ticketTypesSnap.docs) {
          amount += ticket.data().price - (ticket.data().fee?.fixed??0) - ticket.data().price * (ticket.data().fee?.percentage??0) / 100;
          
          if(time.getTime() < ticket.data().confirmed_timestamp?.toDate().getTime()) {
            time = ticket.data().confirmed_timestamp?.toDate();
          }
        }

        setPayoutAmount(amount / 100);

        if(ticketsSnap.docs.every(e => e.data().paid_out)) {
          setPaidOut(true);
          setPayoutTime(ticketsSnap.docs.reduce((a, b) => a.data().paid_out_timestamp.toDate() > b.data().paid_out_timestamp.toDate() ? a : b).data().paid_out_timestamp.toDate());
        } else {
          setPaidOut(false);
          time = new Date(time.getTime() + 604800000);
          if(time.getHours() < 12) {
            setPayoutTime(new Date(time.getFullYear(), time.getMonth(), time.getDate(), 12));
          } else {
            setPayoutTime(new Date(time.getFullYear(), time.getMonth(), time.getDate() + 1, 12));
          }
        }
      });
    }, [id]);
    
    useEffect(() => {
      if(ticketSalesState === 0){
         setTicketSalesActiveSums(ticketSalesTodaySums)
         setTicketSalesActiveNum(ticketSalesTodayNum)
         setTicketSalesActiveLabels(ticketSalesTodayLabels)
         setTicketSalesActiveText("Heute")
      }
      else if(ticketSalesState === 1){
          setTicketSalesActiveSums(ticketSalesYesterdaySums)
          setTicketSalesActiveNum(ticketSalesYesterdayNum)
          setTicketSalesActiveLabels(ticketSalesYesterdayLabels)
          setTicketSalesActiveText("Gestern")
      }
      else if(ticketSalesState === 2){
          setTicketSalesActiveSums(ticketSalesWeekSums)
          setTicketSalesActiveNum(ticketSalesWeekNum)
          setTicketSalesActiveLabels(ticketSalesWeekLabels)
          setTicketSalesActiveText("Woche")      
      }
      else if(ticketSalesState === 3){
          setTicketSalesActiveSums(ticketSalesLast30DaysSums)
          setTicketSalesActiveNum(ticketSalesLast30DaysNum)
          setTicketSalesActiveLabels(ticketSalesLast30DaysLabels)
          setTicketSalesActiveText("Letzte 30 Tage")      
      }
    },[ticketSalesState,statsLoaded])

  useEffect(() => {
    if(reloadTicketCategories  && id){
      getDocs(collection(firestore, "events", id, "tickets")).then((category) => {
        setTicketKategorien(category.docs);

        let total_tickets_all_categories = 0;
        let available_tickets_all_categories = 0;

        category.docs.forEach((doc) => {
          total_tickets_all_categories += doc.data().total_tickets;
          available_tickets_all_categories += doc.data().available_tickets;
        });

        const state = available_tickets_all_categories === 0 ? "Ausverkauft" : "Nicht ausverkauft";
        setStatusTicketing(state);
        setTotalTicketsEvent(total_tickets_all_categories);
        setAvailableTicketsEvent(available_tickets_all_categories); // Update available tickets

        const totalSoldTickets = total_tickets_all_categories - available_tickets_all_categories;
        const completionPercentage = (totalSoldTickets / total_tickets_all_categories) * 100;
        setCompletedTotal(completionPercentage);
      });
      setReloadTicketCategories(false);
    }
  }, [id, reloadTicketCategories]);

  async function downloadPDF(){
    if(!pdfRef || !pdfRef.length) return
    let fileRef = ref(storage,pdfRef)
    let downloadUrl = await getDownloadURL(fileRef)
    window.open(downloadUrl, '_blank').focus()
  }

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    useEffect(() => {
      const handleResize = () => setWindowWidth(window.innerWidth);
      window.addEventListener('resize', handleResize);
      return () => window.removeEventListener('resize', handleResize);
    }, []);

    // For Modal Payout
    const [showPayout,setShowPayout] = useState(false)
    const handleClosePayout = () => setShowPayout(false);

    return(
      isParentEvent ? 
      <div id="noTicketing">
        <div>Das funktioniert noch nicht mit Eventreihen!</div>
      </div>
      :
      ["standard", "hidden","completed"].includes(eventData?.ticket) ?
        <div className="ticketingTabBoxTotal align_center justify_center" id="ticketing">
          <InstantPayout show={showPayout} handleClose={handleClosePayout} eventId={id} payoutAmount={payoutAmount} />
          <div className="ticketingTabBoxLeft">
            <div className="ticketingTabBox" style={{height: "100%"}}>
              <div className="row-flex eckdatenText justify_space_between">
                <div> Übersicht </div>
                {eventOver && hasInvoice ? 
                  <div className="AbrechnungBox pointer" onClick={downloadPDF}>{pdfIcon} Abrechnung</div> 
                : <></>}
              </div>
              <div className="flex col-flex flex-col justify_space_between" style={{height: "100%", marginTop: "20px"}}>
                <div className="ticketCategoriesFlex">
                  {ticketKategorien.map((category, key) => {
                    const totalTickets = category.data().total_tickets;
                    const availableTickets = category.data().available_tickets;
                    const completed = ((totalTickets - availableTickets) / totalTickets) * 100;
                    return (
                      <div className="ticketCategoriesRow" key={key}>
                        <div className="ticketCategoriesLeft">
                          <div className="medium">{category.data().name}</div>
                          <div className="row-flex gap-1">
                            <div className="t-secondary">{((totalTickets - availableTickets) * category.data().price / 100).toFixed(2).replace('.', ',')}€ -</div>
                            <div style={{color: "var(--lightgrey)"}}>({(totalTickets - availableTickets) + "/" + totalTickets})</div>
                          </div>
                        </div>
                        <ProgressBar
                          bgColor="var(--blue)"
                          baseBgColor="none"
                          barContainerClassName="borderRadius-5px"
                          animateOnRender={true}
                          labelAlignment="outside"
                          borderRadius="15px"
                          height="30px"
                          className="progressBarTicketingTab"
                          labelClassName="Montserrat_400 textProgressBar"
                          completed={Math.round(completed)}
                          maxCompleted={100}
                          width="200px"
                        />
                      </div>
                    );
                  })}
                </div>
                <div className="ticketCategoriesRow" style={{marginTop: "15px"}}>
                  <div className="ticketCategoriesLeft">
                    <div style={{ fontSize: "var(--fontsize-big)", fontWeight: "600" }}>Insgesamt</div>
                    <div className="row-flex gap-1">
                      <div className="t-secondary">{ticketKategorien.reduce(
                        (acc, category) => acc + ((category.data().total_tickets - category.data().available_tickets) * category.data().price / 100), 0
                      ).toFixed(2).replace('.', ',')}€ -</div>
                      <div style={{ fontSize: "var(--fontsize-medium)", color: "var(--textSecondary)"}}>({totalTicketsEvent - availableTicketsEvent} / {totalTicketsEvent})</div>
                    </div>
                  </div>
                  <ProgressBar
                    bgColor="var(--blue)"
                    baseBgColor="none"
                    barContainerClassName="borderRadius-5px"
                    animateOnRender={true}
                    labelAlignment="outside"
                    borderRadius="15px"
                    height="30px"
                    className="progressBarTicketingTab"
                    labelClassName="Montserrat_400 textProgressBar"
                    completed={Math.round(completedTotal)}
                    maxCompleted={100}
                    width="200px"
                  />
                </div>
              </div>
            </div>
            <div className="ticketingTabBox">
              <div className="row-flex eckdatenText">Funktionen</div>
              <div className="FunctionsOberbox">
                <div className="FunctionsBox">
                  <div className="col-flex" style={{width:"70%"}}>
                    <div className="FunctionsHeader">Ticketliste</div>
                    <div className="FunctionsSubheader">Siehe den Status und alle Details zu jedem Ticket</div>
                  </div>
                  <Link to={"/dashboard/events/ticket-overview/" + id} className="ticketoverview-btn">
                    {windowWidth < 768 ? chevronRightIcon : "Zur Ticketliste"} 
                  </Link>
                </div>
                <div className="FunctionsBox">
                  <div className="col-flex" style={{width:"70%"}}>
                    <div className="FunctionsHeader">Gästeliste</div>
                    <div className="FunctionsSubheader">Schreibe oder importiere Namen auf deine Gästeliste</div>
                  </div>
                  <div onClick={() => {setShowGaesteListe(true)}} className="ticketoverview-btn">
                    {windowWidth < 768 ? chevronRightIcon : "Zur Gästeliste"}
                  </div>
                </div>
                <div className="FunctionsBox">
                  <div className="col-flex" style={{width:"70%"}}>
                    <div className="FunctionsHeader">Türsteher Codes</div>
                    <div className="FunctionsSubheader">Erstelle Zugangscodes für die Scannapp für deine Türsteher</div>
                  </div>
                  <div className="ticketoverview-btn" onClick={() => {setShow(true)}}>
                    {windowWidth < 768 ? chevronRightIcon : "Zu den Scanner Codes"}
                  </div>
                </div>
                {permissions?.ticketing?.edit && <div className="FunctionsBox">
                  <div className="col-flex" style={{width:"70%"}}>
                    <div className="FunctionsHeader">Ticketing bearbeiten</div>
                    <div className="FunctionsSubheader">Erstelle neue Ticketarten oder passe die Anzahl bestehender an</div>
                  </div>
                  <div className="ticketoverview-btn" onClick={(e) => {props.setShowModal(true); e.preventDefault()}}>
                    {windowWidth < 768 ? chevronRightIcon : "Ticketing bearbeiten"}
                  </div>
                </div>}
              </div>
            </div>
            {/* <div className="ticketingTabBox" style={comingSoon ? {height: "250px"} : {minHeight: "150px"}}>                  
              <div className="row-flex eckdatenText">
                <div> Gutscheincodes </div>
                {<div className="box-title-second-ele" style={{width:"100%"}}>
                  { comingSoon ? "" : 
                    noCoupons ? <></> :
                  <Link to="/dashboard/discount-codes" style={{display: "flex", color: "var(--blue)"}}>
                    <div className="col-flex justify_center align_center" style={{backgroundColor:"var(--blue)",borderRadius:"5px", width: "100px"}}>
                      <div className="uebersichtTitelDetails" style={{margin:"10px",color: "var(--textMain)",width:"100%",textAlign:"center"}}>Neuer Code</div>
                    </div>
                  </Link> 
                    }
                </div>}
              </div>
              <div className="coupon-box-body-flex" id="coupon-flex" style={
                comingSoon 
                ? { justifyContent: "center", alignItems: "center", overflowY: "hidden" } 
                : noCoupons 
                  ? { overflowY: "auto", minHeight: "180px", maxHeight: "200px" }
                  : { justifyContent: "center", alignItems: "center", overflowY: "hidden" }
              }>
              { comingSoon ? <div>Coming Soon</div> :  noCoupons 
                ? 
                  <div className="col-flex justify_center align_center">
                    <div id="noUpcoming" className="animate__animated animate__fadeInDown" style={{fontSize:"var(--fontsize-big)",color: "var(--textSecondary)", marginBottom: "15px"}}>Du hast noch keine Gutscheincodes!</div>
                    <Link to="/dashboard/discount-codes" className="newCodeBtn"> {plusIcon} Neuer Code </Link>
                  </div>
                :
                  coupons.map((coupon, index) => {
                    return(
                      <div className="coupon-col-item" style={{width: "99%"}}>
                        <div className="coupon-col-item-left">
                          <div className="circleNormal" style={{backgroundColor:"var(--bg7)"}}>
                            <div className="couponVal">{coupon.data().discount_absolute ? (coupon.data().discount_absolute / 100) + '€' : (coupon.data().discount_percent + '%')}</div>
                          </div>
                          <div className="coupon-code-name">"{coupon.data().code}"
                            <div className="coupon-code-subname">{coupon.data().discount_absolute ? `${coupon.data().discount_absolute / 100}€ Rabatt` : `${coupon.data().discount_percent}% Rabatt`}</div>
                          </div>
                        </div>
                        <div id={index} className="copy-btn" onClick={() => {copyToClipBoard(index,coupon.data().code)}}> Kopieren </div>
                      </div>
                    )
                  })
                }
              </div>
            </div> */}
          </div>
          <div className="ticketingTabBoxRight">
            <div className="ticketingTabBox" id="ticketingTabSales">
              <div className="box-title-flex">
                <div className="box-title-first-ele">
                  <div className="eckdatenText">
                    Ticketverkäufe
                  </div>
                </div>
                <div className="box-title-second-ele" style={{fontSize: "var(--fontsize-large)", gap:"1rem"}}>
                  <div style={{cursor:"pointer", color: "var(--textMain)"}}
                    onClick={() => {
                      if(ticketSalesState === 0)
                      {setTicketSalesState(3)}
                      else{
                        setTicketSalesState(ticketSalesState - 1 )}
                    }}
                  >{chevronLeftIcon}</div>
                  <div className="uebersichtTitelDetails" style={{textAlign:"center", fontSize: "var(--fontsize-large)"}}>{ticketSalesActiveText}</div> 
                  <div style={{cursor:"pointer", color: "var(--textMain)"}}
                    onClick={() => {
                      if(ticketSalesState === 3)
                      {setTicketSalesState(0)}
                      else{
                        setTicketSalesState(ticketSalesState + 1 )}
                    }}
                  >{chevronRightIcon}</div>
                </div>
              </div>
              <div className="numberSales">
                {ticketingLoading ? "Loading..." : (isNaN(ticketSalesActiveNum) ? 0 : ticketSalesActiveNum) + " €"}
              </div> 
              <div className="chart-box">
                <LineGraph data={ticketSalesActiveSums} labels={ticketSalesActiveLabels} colors={['#fdfd44']}/>
              </div>
            </div>
            <div className="ticketingTabBox">
              <div className="box-title-flex">
                <div className="box-title-first-ele">
                  <div className="eckdatenText">
                    Auszahlung
                  </div>
                </div>
              </div>
              <div style={{marginTop: "20px"}}>
                {
                  eventOver && payoutAmount && payoutTime
                    ? paidOut ? <div className="large">Der Betrag von <span className="font-semibold">{payoutAmount.toFixed(2).replace('.', ',')}€</span> wurde am {payoutTime.toLocaleDateString("de-DE",{weekday: 'short', day: '2-digit', month: '2-digit', year: 'numeric'})} um {payoutTime.toLocaleTimeString("de-DE",{hour:"2-digit",minute:"2-digit"})} Uhr auf dein Konto überwiesen.</div>
                    : <div className="large">Der Betrag von <span className="font-semibold">{payoutAmount.toFixed(2).replace('.', ',')}€</span> wird voraussichtlich am {payoutTime.toLocaleDateString("de-DE",{weekday: 'short', day: '2-digit', month: '2-digit', year: 'numeric'})} um {payoutTime.toLocaleTimeString("de-DE",{hour:"2-digit",minute:"2-digit"})} Uhr auf dein Konto überwiesen.</div>
                    : <div className="large">Der Auszahlungsbetrag wird automatisch max. 7 Tage nach dem Event auf dein Konto überwiesen.</div>
                }
              </div>
              {eventOver && payoutAmount && payoutTime && !paidOut && <>
                <div style={{marginTop: "10px", color: "var(--textSecondary)"}}>Für 2,5 % des Auszahlungsbetrags kann eine Sofortauszahlung beantragt werden, die das Geld innerhalb von 30 Minuten aufs Konto bringt.</div>
                <div style={{backgroundColor: "var(--blue)", color: "white", borderRadius: "5px", width: "calc(100% - 20px)", padding: "10px", alignItems: "center", textAlign: "center", cursor: "pointer", marginTop: "10px"}} onClick={() => {setShowPayout(true)}}>Sofortauszahlung</div>
              </>}
            </div>
          </div>
        </div>
      :
      <div id="noTicketing">
        <div>Du hast bei diesem Event noch kein Ticketing hinzugefügt!</div>
        {permissions?.ticketing?.edit && <div className="newPassw flex-gap-1rem" onClick={() => {props.setShowModalAnlegen(true)}}>
          <div>{plusIcon}</div>
          <div>Für dieses Event aktivieren</div>
        </div>}
      </div>
    )
  }



// STATISTIKEN TAB

function StatistikenTab({customerStatuses,customerStatusesLabels,customerGenders,customerGendersLabels,customerAges,customerAgesLabels,customerGenres,customerGenresLabels,customerOrigin,customerOriginLabels,customerClubs,customerClubsLabels}){
  return(
    <div className="mainBoxStatsTab" id="statistics">
      <div className="statisticsTabBox">
        <div className="statisticsBoxTopRow">
          <div className="EventStatisticsHeader">Kundenstamm</div>
          <FurtherInfo
            explanation="Hier siehst du prozentual wie viele der Ticketkäufer wiederkehrende Kunden (Bestandskunden) oder erstmalige Kunden (Neukunden) sind."
          />
        </div>
        <PieChart data={customerStatuses} labels={customerStatusesLabels} colors={['#faab17', '#4ab022']} />
      </div>
      <div className="statisticsTabBox">
        <div className="statisticsBoxTopRow">
          <div className="EventStatisticsHeader">Geschlecht</div>
          <FurtherInfo
            explanation="Hier siehst du die Verteilung deiner Kunden in Geschlechtern. Als Kunde zählen wir hier alle, die mit deinen Events interagiert (zb. gemerkt, geteilt, gekauft) haben."
          />
        </div>
        <PieChart data={customerGenders} labels={customerGendersLabels} colors={['#0d6efd', '#f20000', '#8b44fd']}/>
      </div>
      <div className="statisticsTabBox">
        <div className="statisticsBoxTopRow">
          <div className="EventStatisticsHeader">Alter</div>
          <FurtherInfo
            explanation="Hier siehst du die Verteilung deiner Kunden in Altersgruppen. Als Kunde zählen wir hier alle, die mit deinen Events interagiert (zb. gemerkt, geteilt, gekauft) haben."
          />
        </div>
        <BarChart data={customerAges} labels={customerAgesLabels} colors={['#fdfd44']}/>
      </div>
      <div className="statisticsTabBox">
        <div className="statisticsBoxTopRow">
          <div className="EventStatisticsHeader">Musikgenre</div>
          <FurtherInfo
              explanation="Hier siehst du in welchen Musikgenre deine Kunden oft Events suchen."
            />
        </div>
        <LineChart data={customerGenres} labels={customerGenresLabels} colors={['#f20000']}/>
      </div>
      <div className="statisticsTabBox">
        <div className="statisticsBoxTopRow">
          <div className="EventStatisticsHeader">Herkunft</div>
          <FurtherInfo
            explanation="Hier siehst du aus welchen größeren Ballungsräumen deine Kunden kommen."
          />
        </div>
        <LineChart data={customerOrigin} labels={customerOriginLabels} colors={['#faab17']}/>
      </div>
      <div className="statisticsTabBox">
        <div className="statisticsBoxTopRow">
          <div className="EventStatisticsHeader">Alternative Clubs</div>
          <FurtherInfo
            explanation="Hier siehst du in welche anderen Clubs deine Gäste noch gerne gehen."
          />
        </div>
        <LineChart data={customerClubs} labels={customerClubsLabels} colors={['#8b44fd']}/>
      </div>
    </div>
  )
}

// Galerie TAB

function GalerieTab({id,isParentEvent,eventHasPassed,...props}){
  
  const { organizerId } = useContext(UserContext);

  function uploadGallery() {
    document.getElementById("getFile")?.click();
  }

  //upload state stuff
  const [uploading, setUploading] = useState(false);
  const [filesUploaded, setFilesUploaded] = useState(0);
  const [filesTotal, setFilesTotal] = useState(0);
  const [progress, setProgress] = useState(0);

  //gallery imags
  const [images, setImages] = useState([]);
  useEffect(() => {
    onSnapshot(query(collection(firestore, 'gallery'), where('event', '==', id)), (snapshot) => setImages(snapshot.docs));
  }, [images]);

  useEffect(() => {(async () => {
    if(!props.banner) return;
    document.getElementById("output_image").style.visibility='visible';
    document.getElementById('UploadBtn').style.visibility='hidden';
    const downloadUrl = await getDownloadURL(ref(storage, props.banner));
    document.getElementById('output_image').src = downloadUrl;
  })()}, [props.banner]);
  
  return(
    <>
        <div id="noTicketing">
          {
          isParentEvent ? 
          <div>Das funktioniert noch nicht mit Eventreihen!</div>
          :
          eventHasPassed
            ? <>
            <input
              type="file"
              multiple="multiple"
              id="getFile"
              className="imgInp"
              accept="image/png ,image/jpeg, image/jpg"
              onChange={async (evt, i) => {
                const files = evt.target.files;
                setFilesTotal(files.length);
                setUploading(true);
                for (let i = 0; i < files.length; i++) {
                  const file = files[i];
                  const storageRef = ref(storage, 'organizers/' + organizerId + '/gallery/' + id + '-' + file.name);
                  const uploadTask = uploadBytesResumable(storageRef, file);
                  uploadTask.on('state_changed', (snapshot) => {
                    const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                    setProgress(progress);
                  }, (error) => {
                    alert('Es ist beim Hochladen ein Fehler aufgetreten. Bitte versuche es erneut.');
                    console.error(error);
                  }, () => {
                    setFilesUploaded(i + 1);
                    setProgress(0);
                    if (files.length === i + 1) {
                      setUploading(false);
                    }
                  });
                  await uploadTask;
                  await setDoc(doc(firestore, 'gallery', id + '-' + file.name), {
                    image: storageRef.fullPath,
                    event: id,
                    organizer: organizerId,
                  });
                }
                evt.target.value = null;
              }}
            />
            {images?.length ? <div className="gallery-images-container">
              {images.map((image) => <div className="gallery-image-box">
                <FirebaseStorageImage reference={image.data().image} resolution="512" />
              </div>)}
              <div>
                {!uploading 
                  ? <button
                      onClick={uploadGallery}
                      className="UploadBtnGallery"
                    > Weitere Bilder hochladen
                    </button>
                  : <div> {filesUploaded}/{filesTotal} Bilder hochgeladen ({(filesUploaded * 100 / filesTotal + progress / filesTotal).toFixed(0)}%)</div>
                }
              </div>
            </div> : <>
                <div>Lade hier die Bilder zu deinem Event hoch:</div>
                
              {!uploading ? <button
                onClick={uploadGallery}
                className="UploadBtnGallery"
              >
                Bilder auswählen
              </button> : <div>
                {filesUploaded}/{filesTotal} Bilder hochgeladen ({(filesUploaded * 100 / filesTotal + progress / filesTotal).toFixed(0)}%)
              </div>}
            </>
            }</>: <div>Hier kannst du nach dem Event Bilder zu deinem Event hochladen</div>
          }
        </div>
    </>
  )
}

export { EventEinzeln };